<template>
  <div>
    <TopParallax
      title="SPONSORSHIPS"
      :img-src="imgSrc"
    />
    <SponsorContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import SponsorContent from '@/components/hope/SponsorContent.vue'

export default {
  components: {
    TopParallax,
    SponsorContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/slide5.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Sponsorships'
      }
    }
  }
}
</script>
