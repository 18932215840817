<template>
  <v-carousel
    class="pa-0"
    cycle
    height="auto"
    :show-arrows-on-hover="true"
    :hide-delimiters="true"
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      fade
      eager
    >
      <v-img :src="item.src" eager/>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/boys/Boys01.png')
        },
        {
          src: require('@/assets/images/boys/Boys02.png')
        },
        {
          src: require('@/assets/images/boys/Boys03.png')
        },
        {
          src: require('@/assets/images/boys/Boys04.png')
        },
        {
          src: require('@/assets/images/boys/Boys05.png')
        },
        {
          src: require('@/assets/images/boys/Boys06.png')
        },
        {
          src: require('@/assets/images/boys/Boys07.png')
        },
        {
          src: require('@/assets/images/boys/Boys08.png')
        },
        {
          src: require('@/assets/images/boys/Boys09.png')
        },
        {
          src: require('@/assets/images/boys/Boys10.png')
        },
        {
          src: require('@/assets/images/boys/Boys11.png')
        },
        {
          src: require('@/assets/images/boys/Boys12.png')
        },
        {
          src: require('@/assets/images/boys/Boys13.png')
        },
        {
          src: require('@/assets/images/boys/Boys14.png')
        },
        {
          src: require('@/assets/images/boys/Boys15.png')
        },
        {
          src: require('@/assets/images/boys/Boys16.png')
        },
        {
          src: require('@/assets/images/boys/Boys17.png')
        }
      ]
    }
  }
}
</script>