<template>
  <div>
    <v-card color="#f3f2ed" rounded="0">
      <v-container>
        <v-row>
          <v-col cols="12" lg="5">
            <v-card rounded="0">
              <v-container class="ma-2">
                <h4 class="h4 my-2"><b>It costs only $30.00/month to sponsor a child. Your monthly support provides a child with:</b></h4>
                <h5 class="h5 ml-2"><v-icon>mdi-circle-medium</v-icon>Food</h5>
                <h5 class="h5 ml-2"><v-icon>mdi-circle-medium</v-icon>Shelter</h5>
                <h5 class="h5 ml-2"><v-icon>mdi-circle-medium</v-icon>Clothing</h5>
                <h5 class="h5 ml-2"><v-icon>mdi-circle-medium</v-icon>Health Care</h5>
                <h5 class="h5 ml-2"><v-icon>mdi-circle-medium</v-icon>Portable water and sanitation</h5>
                <h5 class="h5 ml-2"><v-icon>mdi-circle-medium</v-icon>Education</h5>
                <h5 class="h5 mt-4">...85% of each donation goes directly to provide for the needs of the children</h5>
                <h5 class="h5 my-2">...15% covers the wages of workers and administrative expenses</h5>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" lg="7">
            <v-card rounded="0">
              <v-container class="ma-2">
                <h4 class="h35 mt-2"><b>PLEASE DONATE</b></h4>
                <v-divider />
                <h5 class="h5 my-4">Every gift you give holds the promise of a brighter future for a child living without hope. It shows your personal commitment to building a world where the cycle of poverty gradually diminishes. We offer you the opportunity to make a one-time or monthly gift. We encourage you to positively impact the life of a beautiful child by clicking on the button below.</h5>
                <v-divider />
                <h5 class="h5 my-4">Become a donor by making your first gift today. <br> Click on the button below to get started.</h5>
                <div class="d-flex justify-center">
                  <v-btn
                    color="red"
                    class="mb-4"
                    outlined
                    large
                    width="200"
                    href="https://www.canadahelps.org/en/dn/45974"
                    target="_blank"
                  >DONATE NOW</v-btn>
                </div>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card rounded="0">
      <v-container>
        <h3 class="h3 text-center">SPONSHORSHIP CARDS</h3>
        <v-row>
          <v-col cols="12" lg="6">
            <h4 class="h5 text-center">GIRLS</h4>
            <v-divider />
            <GirlsCarousel />
          </v-col>
          <v-col cols="12" lg="6">
            <h4 class="h5 text-center">BOYS</h4>
            <v-divider />
            <BoysCarousel />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import GirlsCarousel from '@/components/hope/GirlsCarousel.vue'
import BoysCarousel from '@/components/hope/BoysCarousel.vue'

export default {
  components: {
    GirlsCarousel,
    BoysCarousel
  }
}
</script>
